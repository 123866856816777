import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";
import axios from 'axios'
import { PaymentElement } from "@stripe/react-stripe-js";
function Payment() {  
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  const cartData = useLocation()
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_ORDERAT}/config`,{
        headers:{
            'Content-Type': 'application/json', 
            'Access-Control-Allow-Origin': "*",
            'Access-Control-Allow-Methods': "*"
        }
    }).then(async (r) => {
      const { publishableKey } = await `${r.data.publishableKey}`
      setStripePromise(loadStripe(r.data.publishableKey));
    });
   }, []);

  useEffect(() => {
    let totalpricearray=[]
     cartData?.state?.checkoutData?.map((item)=>{
      return totalpricearray.push(item.count*item.price)
    })
   const totalprice= totalpricearray.reduce((a, b) => a + b, 0)+cartData?.state?.cartdata?.state?.deliveryCharge+parseFloat(cartData?.state?.cartdata?.state?.totaltax)
  //  console.log('cartData',cartData,totalprice)
  //  console.log('cartDatacartData',cartData)
  // console.log(totalprice.toFixed(2)*100)
   axios.post(`${process.env.REACT_APP_ORDERAT}/create-payment-intent`,{
    totalprice:parseInt(totalprice.toFixed(2)*100),
    orderId:cartData?.state?.orderId
  }, {
    headers:{
        'Content-Type': 'application/json', 
        'Access-Control-Allow-Origin': "*",
        'Access-Control-Allow-Methods': "*"
    }
}).then(async (result) => {
    // console.log('result',result)
    var { clientSecret } = await `${result.data.clientSecret}`;
    setClientSecret(result.data.clientSecret);
    // console.log('result',result)
  });
   }, []);

  return (
    <>
    {/* {console.log('cartData',cartData)} */}
   <div className='MainWhole-wrapper-container'> 
		<Header/>
		<div className="Checkout-StripeMain-wrapper">
			<h1 >
				Stripe
			</h1>
			<h3> 
				Enter Your Payment Details
				</h3> 
                {clientSecret && stripePromise && (
           <Elements stripe={stripePromise} options={{ clientSecret }}>
             <CheckoutForm cartData={cartData} clientSecret={clientSecret}/>
          </Elements>
          )}
		</div>	
    	</div>
     
    </>
  );
}

export default Payment;
