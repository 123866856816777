import { Button } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { aeliaItemslist, relayitemslist } from '../Data/ItemList'
import { ItemsList } from '../redux/Actions'
import { useTranslation } from "react-i18next";
import LandingPageHeader from './LandingPageHeader'
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import { SliderData } from '../Data/SliderData'
import Footer from './Footer'
import { useEffect } from 'react'
function Home() {
   
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const handleItemView=(name,items)=>{
        // console.log('name Items', name, items)
        dispatch(ItemsList(items))
        navigate(`/Itemslist/:${name}` , {state:items})
        // window.location.reload()
    }
    const handleOrderNow =()=>{
        navigate('/storelist')  
    }
    const { t } = useTranslation()     
//     const handlehelpButton=()=>{
//      navigate('/help')
//     //  window.location.reload()
//     }
//    const handlemoredetails=()=>{
//     navigate('/moredetails')
//     // window.location.reload()
//    } 

  useEffect(()=>{
    localStorage.setItem('language',"Italian")
    sessionStorage.setItem('deliveryLocation',null)
  },[]) 
  return (
    
     < >
  <div className='MainWhole-wrapper-container-2'>      
       <LandingPageHeader/>
        <div className='HomePage-Main-Wrapper-Container'>
               <h1 className="HomePage-Main-Heading-text">Order from restaurants near you</h1>
                <h4 className="HomePage_How_It_Works_text" > How It Works</h4>
                <OwlCarousel items={1}  
                            className="owl-theme"  
                            loop  
                            dots={true}
                            nav={true}
                            center={true}>
                {SliderData.map((data , key)=>{
                    return (
                        <div key={key} className='HomePage_slider_div-class' 
                        // style={{backgroundImage:`url(${data.imgurl})`}}
                        >
                         <div className="HomePage_slider_content_div_content">
                           {/* <label> {data.StepContent}</label> */}
                               <img src={data.imgurl}/>
                           <h4>    {data.imageContent}</h4>
                        </div>
                        </div>
                    )
                })}      
             </OwlCarousel>
            <button className='HomePage_order_now_button' onClick={handleOrderNow}> Order Now</button>                   
        </div>  
         {/* <div className="HomePage_section_two_wrapper"> 
            <img src="/assets/images/xbridge-language.png"/>
         </div> */}
        <Footer/> 
    </div>
    </>
  )
}

export default Home