import { FormControl, MenuItem, Select } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import i18next from 'i18next';
import { useEffect } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

function HeaderCongratulations(props) {

  const[currentlanguage, setcurrentlanguage] =useState("Italian")
  const language = localStorage.getItem('language')
  const handlelogoutbtn=()=>{
	   localStorage.setItem('email',null)
       localStorage.setItem('password',null)
	   localStorage.setItem('language', null)
  }
  const navigate= useNavigate()
  const handleBackbutton=()=>{
	navigate(-1)
  } 
  const [isscroll, setisscroll]=useState(false)
  useEffect(()=>{
	window.addEventListener('Scroll', setisscroll(!isscroll));
  },[])
   return (
    <div className={props.open || props.opentwo ? 'MainHeader-wrapper_copy headerscroll':'MainHeader-wrapper'}>
		{/* {console.log('isscroll',isscroll)} */}
       <div className='MainHeader-logo-div-wrapper Header_div_congratulations'>
       <div className='MainHeader-logo-div'>
        <p> <ArrowBackIosNewIcon onClick={handleBackbutton} style={{cursor:'pointer', fontSize:'39.4px', color:"#40B4D0"}}/> </p>
		  <Link to="/" onClick={handlelogoutbtn}><img className='Congratulation_header_image' src="/assets/images/orderatcvg.png" />
		 </Link>
	   </div> 
       {/* {console.log('props',props)} */}
       <div className='Congratulation_header_2'>
                <PersonOutlineOutlinedIcon/> 
                <div> 
                <h5>{props?.orderData?.state?.state?.cartdata?.state?.username} </h5>
		        <p> +{props?.orderData?.state?.state?.cartdata?.state?.contactNo}</p>
                </div>
      </div>
	 </div>   
    </div>
  )
}

export default HeaderCongratulations