import React from 'react'
import BillDetailsHeader from './BillDetailsHeader'
import { useLocation, useNavigate } from 'react-router-dom'
import { DecrementCount, IncrementCount } from '../redux/Actions'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useState } from 'react'
import Footer from './Footer'

function BillDetails() {

  const [refresh, setrefresh] = useState(false)
  const [totalprice, settotalprice] = useState(0)
  var totaltax =0
  const [topay, settopay] = useState(0)
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const navigate = useNavigate();

  const { cart } = useSelector(state => state.IC)
  const billdata = useLocation()
  useEffect(() => {
    // console.log('billdata', billdata)
    setDeliveryCharge(billdata?.state?.statetwo?.deliveryfee)
    let totalpricearr = []
    billdata?.state?.stateone.map((item) => {
      totalpricearr.push(item.price * item.count)
    })
    settotalprice(totalpricearr.reduce((a, b) => a + b, 0))
  }, [refresh])

 
  //    console.log('billdata', billdata) 
  const dispatch = useDispatch()

  const handleproceedbutton = () => {
    navigate('/selectDetails', { state: { stateone: billdata?.state?.stateone, statetwo: billdata?.state?.statetwo ,totalPrice:totalprice?.toFixed(2), deliveryCharge:deliveryCharge , totaltax:totaltax?.toFixed(2) } })
  }

  // console.log('billdata', billdata)
  const calculateTax=(total, taxpercent)=>{
    let tax =(total*taxpercent)/100
    // settotaltax(tax)]
    totaltax=tax
    // console.log('totaltax',totaltax,'tax',tax)
  }
  return (
    <div className='MainWhole-wrapper-container'>

      <BillDetailsHeader billdata={billdata.state?.statetwo} />
      <div className="DetailsPage-Main-wrapper">
        <div className='d_flex_center'> 
        <h2> Cart </h2>
        <img src="/assets/images/edit-cart.svg" className='EditIcon_image' onClick={()=>{navigate(-1)}} style={{ cursor: 'pointer' }} />
        </div>
        <div className="DetailsPage-content-wrapper">
          {billdata?.state?.stateone?.map((item , index) => {
            return (
              <div key={index} className="ItemsListPage-ItemsList-containers">
                <div className='product-content-wrapper'>
                  <div className='product-content-wrapper-inner-div-one'>
                    <img src={item.image} className='product-images-class' />
                  </div>
                  <div className='product-content-wrapper-inner-div-two'>
                    <h4> {item.en.name} <span className='Bill_Details_content_itemcount'>x{item.count}</span></h4>
                    {/* <div className='Bill_Details_content_itemcount'> <h3>  </h3></div>                                         */}
                  </div>
                </div>
                <p style={{ textAlign: "end" }}>${(item.price)*(item.count)}</p>
              </div>
            )
          })}
        </div>
        <div className="BillDetails_page_BillTotal_wrapper">
          <h3> Bill Details</h3>
          <div className="DetailsPage-content-wrapper" style={{ paddingTop: "10px" }}>
            <div className='d_grid_column BillDetails_item_total_wrapper'>
              <div className="text_align_start"> <h4> Item Total</h4></div>
              <div className="text_align_end"> <p> ${totalprice.toFixed(2)}</p></div>
            </div>
            <div className='d_grid_column BillDetails_item_total_wrapper'>
              <div className="text_align_start"> <h4> Delivery Fee</h4></div>
              <div className="text_align_end"> <p> ${deliveryCharge.toFixed(2)}</p></div>
            </div>
            <div className='d_grid_column BillDetails_item_total_wrapper'>
              <div className="text_align_start"> <h4> Sales Tax ({billdata?.state?.statetwo.tax}%)</h4></div>
              <div className="text_align_end"> <p> {calculateTax(totalprice,billdata?.state?.statetwo.tax)}{totaltax.toFixed(2)}</p></div>
            </div>
            <div className='d_grid_column BillDetails_item_topay_wrapper'>
              <div className="text_align_start"> <p> To Pay</p></div>
              <div className="text_align_end"> <p> ${(totalprice + deliveryCharge + totaltax).toFixed(2)}</p></div>
            </div>
          </div>
        </div>
        <div className='Billpage_proceed_tonext_btn_wrapper'>
          <h3> ${(totalprice + deliveryCharge+totaltax).toFixed(2)}</h3>
          <button onClick={handleproceedbutton} className='BillPage_proceed_to_btn'> Proceed to Pay</button>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default BillDetails
