import { BottomNavigation, BottomNavigationAction, InputLabel, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import Header from './Header'
import { Form, Formik, useFormik } from 'formik';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from 'react-toastify';
import { getlocationlistmapId, sendMessage } from '../API';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Footer from './Footer';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

function Details() {

  const [btndisable, setbtndisable] = useState(false)
  const dispatch = useDispatch()
  const otpSuccess = () => toast.success('OTP send Successfully')
  const { cart } = useSelector(state => state.IC)
  const otpError = () => toast.error('OTP send Failed')
  const Data = useLocation()
  // console.log('Data', Data)
  const cartData = Data.state.cartdata
  const locationName = Data.state.locationName
  const storeId=Data.state.storeId
  const navigate = useNavigate()
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const validationSchema = yup.object({
    contactno: yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone Number is required'),
    username: yup.string().required('User Name is required'),
    agree:yup.string().required('Please select this')
  })
  const storetype = localStorage.getItem('store_type')
  
  const formik = useFormik({
    initialValues: {
      contactno: '',
      username: '',
      agree:''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
        //  console.log('values',values)
      let message = Math.floor(100000 + Math.random() * 900000)
      sendMessage(values.contactno, message, values.username)
        .then(res => {
          // console.log('OTP Send Success' ,res)
          if (res.data.status === 200 || res.data.status === "200") {
            let contactNo = values.contactno
            let username = values.username
            otpSuccess()
            navigate('/cart', { state: {cartData, message, locationName, contactNo, username, statetwo: Data?.state?.statetwo,totalPrice:Data?.state?.totalPrice,deliveryCharge:Data?.state?.deliveryCharge , totaltax:Data?.state?.totaltax ,storeId} })
            setbtndisable(true)
          }
        })
        .catch((err) => {
          console.log('Error in send OTP', err)
          otpError()
        })
    },
  });
  const onValueChange = (value) => {
    formik.setFieldValue('contactno', value)
  }
  const handlecartedit = () => {
    navigate(`/storelist/${Data?.state?.statetwo?._id}`,{state:{item:Data?.state?.statetwo}})
  }
  // console.log('Data',Data) 
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const handleagreechange=(e)=>{
    if(e.target.checked){
      formik.setFieldValue('agree',e.target.checked)
    }
    else{
      formik.setFieldValue('agree','')
    }
     
  }
  return (
    <>
      <div className='MainWhole-wrapper-container'>
        <Header />
        {/* {console.log('DataData',Data)} */}
        <div className="DetailsPage-Main-wrapper">
          {/* <h2> {t('cart_page_heading')}</h2> */}
          <h2> Enter your details</h2>
          <form onSubmit={formik.handleSubmit}
          // style={{height:'100vh'}}
          >
            <div className="DetailsPage-content-wrapper">

              <div className="DetailsPage-content-wrapper-inner">
                {/* <InputLabel style={{fontFamily:"'Poppins', sans-serif"}} className='detailspage-content-label'> {t('cart_page_phone_number')} <sup style={{color:"red"}}>*</sup></InputLabel> */}
                <h3 className="DetailsPage-content-wrapper-inner_h3"> Enter Phone Number*</h3>
                <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                  <div className="inline"
                    style={{ width: '100%', margin: 'auto' }}>
                    <PhoneInput
                      style={{ marginBottom: '10px', textAlign: 'justify' }}
                      country={'us'}
                      specialLabel='Phone Number'
                      enableSearch={true}
                      autoFocus={true}
                      value={formik.values.contactno}
                      onChange={(value) => onValueChange(value)}
                      countryCodeEditable={false}
                      margin="standard"
                      name="contactno"
                      label="Phone Number"
                      required
                      containerStyle={{}}
                      inputStyle={{
                        border: '2px solid rgb(221 219 219)',
                        width: '100%',
                        padding: '8px 60px'
                      }}
                      dropdownStyle={{}}
                      error={formik.touched.contactno &&
                        Boolean(formik.errors.contactno)}
                      helperText={formik.touched.contactno && formik.errors.contactno}
                    />

                  </div>

                </div>
                {formik.errors.contactno && formik.touched.contactno ? <p style={{ color: '#d32f2f', fontSize: '0.75rem', marginTop: '-2px', textAlign: 'left', paddingLeft: '0px' }}>Please Enter Phone Number</p> : null}
              </div>
              <div className="DetailsPage-content-wrapper-inner Details_page_input_wrapper">

                <h3 className="DetailsPage-content-wrapper-inner_h3">Name*</h3>
                <TextField
                  id="standard-size-normal"
                  fullWidth
                  variant="outlined"
                  value={formik.values.username}
                  name="username"
                  onChange={formik.handleChange}
                  placeholder='Name'
                  error={formik.touched.username &&
                    Boolean(formik.errors.username)}
                  helperText={formik.touched.username && formik.errors.username}
                  sx={{ backgroundColor: "white" }}
                />
              </div>
              {/* {formik.errors.username && formik.touched.username ? <p style={{ color: '#d32f2f', fontSize: '0.75rem', marginTop: '-2px', textAlign: 'left', paddingLeft: '0px' }}>Please Enter UserName</p> : null} */}
              <div className="Details_page_agree_checkBox">
                 <Checkbox onChange={(e)=>handleagreechange(e)} {...label} error={formik.touched.agree && Boolean(formik.errors.agree)}  helperText={formik.touched.agree && formik.errors.agree}  />
                  <p> Click to accept <a className='Help_page-content-p_two' href="mailto:support@ottonomy.io"> Privacy Policy</a> and <a className='Help_page-content-p_two' href="mailto:support@ottonomy.io">Terms & Conditions</a> </p>
              </div>
          
            </div>
            <div className="BillDetails_page_BillTotal_wrapper">
              {storetype==='self' && <div className='d_flex_center'>
                <h3>Cart Details</h3>
                <img src="/assets/images/edit-cart.svg" className='EditIcon_image' onClick={handlecartedit} style={{ cursor: 'pointer' }} />
                {/* <EditOutlinedIcon onClick={handlecartedit} sx={{cursor:'pointer'}}/> */}
              </div>} 
              <div className="DetailsPage-content-wrapper">
              {storetype==='self' && <>{cartData?.map((item ,index) => {
                  return (
                    <div key={index} className=' BillDetails_item_total_wrapper'>
                      <div className="text_align_start"> <p> {item.en.name}</p></div>
                      <div className="text_align_end"> <h4> X{item.count}</h4></div>
                    </div>)
                })} </>}
                <div className='BillDetails_item_topay_wrapper'>
                  <div className="text_align_start"> <p> Selected Gate:</p></div>
                  <div className="text_align_end"> <h4> {locationName}</h4></div>
                </div>
              </div>
            </div>
            <div className="Details-page-Nextbutton-wrapper">
            {formik.errors.agree && formik.touched.agree ? <p className="SelectDelivery_page_error_p">Please Agree to Terms and conditions</p> : null}
              {formik.values.contactno === '' ? <div className='Details_page_disabled_button'> Send One-Time Password (OTP) <ArrowForwardIosIcon /></div> :
                <button type="submit" style={{ cursor: 'pointer' }}>
                  {/* {t('Details_page_next_button')} */}
                 
                  Send One-Time Password (OTP)
                  <ArrowForwardIosIcon />
                </button>}
            </div>
          </form>
        </div>
        <ToastContainer />

      </div>
      <Footer />
    </>
  )
}

export default Details