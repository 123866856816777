import { createStore } from 'redux'
import {ItemCounter,ItemsListReducer,otpNumberReducer, paymentReducer} from './reducers/index.js'
import { configureStore ,combineReducers } from '@reduxjs/toolkit'
import  thunk  from 'redux-thunk'
const mutipleReducers = combineReducers({
    IC:ItemCounter,
    ItemsListReducer,
    otpNumberReducer,
    paymentReducer
})
var initialState={}
const store = configureStore({
    reducer:mutipleReducers,
    middleware:[thunk],
    preloadedState:initialState
})

export default store