import React, { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getproductlist, getstorelist } from '../API'
import { SliderData } from '../Data/Storelist'
import Footer from './Footer'
import Header from './Header'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
function StoreList() {
  const navigate =useNavigate()

  const handlestoreclick=(item)=>{
      let storeId = item.storeId
        if(item.type==='hms'){
          navigate('/selectDetails' , {state:{storeId}})
          localStorage.setItem('store_type', "hms")
        }
        else{
          navigate(`/storelist/${item.storeId}`,{state:{item:item}})
          localStorage.setItem('store_type', "self")
        }
        
  }
  const [storelist, setstorelist] =useState()
  useEffect(()=>{
    getstorelist().then((res)=>{
        // console.log('Storelist response',res)
        setstorelist( res.data.message?.sort((a, b) => {
          const nameA = a.storeId.toLowerCase();
          const nameB = b.storeId.toLowerCase();
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          return 0;
        }))
       
    }).catch((err)=>{
          //  console.log(err)
    })
    

  },[])

    return (
    <div className='MainWhole-wrapper-container'>  
      <Header/>
      <div className='ItemListMain-Wrapper'>
        <div className="ItemList_page_Content_wrapper"> 
           <h1> Hello @HMS Host Innovation Lab</h1>
            <p> We’ve got great options if you’ve got time.</p>
        </div>
        <div className='Storelist-container-wrapper'>
          {/* {   console.log('storelist',storelist)} */}
            <h3> {storelist?.length} stores near you </h3>
         {storelist?.map((item, index)=>{
            return(
             <div key={index} className='Storelist_container_inner_item_wrapper' onClick={ ()=> {(item.status ===0 || item.capacity) || handlestoreclick(item)}}> 
                <img src={item.image} className={item.status===0 || item.capacity  ? "inactive_store":''}/>
                <div>  
                {item.status===0 || item.capacity  ? 
                 <span className="Storelist_page_comingsoon_img">
                  {/* <img src="/assets/images/coming-soon.svg"/> */}
                 </span>:''} 
                    <div className='Storelist_container_inner_item_wrapper_content_div'> 
                        <p className={item.status===0 || item.capacity  ? 'inactive_store':"d_flex_start Storelist_container_inner_item_wrapper_content_p"}><AccessTimeOutlinedIcon/> 15 mins </p>
                        <h4>{item.en.name}</h4>
                        {/* <h6>{item.distance}</h6> */}
                        {/* {console.log('item',item)} */}
                        <p> {item.en.description}</p>   
                        <h6 className={item.status===0 || item.capacity ? "inactive_store":''}>{item.location}</h6>  
                        {item.status === 0|| item.capacity   ? <p className='p_error'> Currently Unavailable</p> :null}
                    </div> 
                    </div>
             </div>
            )
         })}   
          
       </div> 
      </div>
      <Footer/>
    </div>
  )
}

export default StoreList
