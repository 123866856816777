import React, { useState } from 'react'
import Header from './Header'
import { Form, Formik, useFormik } from 'formik';
import * as yup from 'yup';
import { InputLabel, TextField } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DecrementCount, IncrementCount, otpaction } from '../redux/Actions';
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from 'react-toastify';
import { getdeliverytime, orderbooking, orderbookingtwo, placeorder, sendMessage, sendMessagetwo } from '../API';
import { useEffect } from 'react';
import OTPInput, { ResendOTP } from "otp-input-react";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Footer from './Footer';
function Cart() {
  const otpresend = () => toast.success('OTP send Successfully')
  const otpSuccess = () => toast.success('Valid OTP ')
  const otpError = () => toast.error('Invalid OTP')
  const Bookingerror = () => toast.error('Unexcepted Error')
  const { cart } = useSelector(state => state.IC)
  const placeorderSuccess = () => toast.success('Order Place successfully ')
  const placeorderError = () => toast.error("Can't place order")
  const [isorderplace, setisorderplace] = useState(false)
  const cartdata = useLocation()
  const [otp, setOtp] = useState('');
  const navigate = useNavigate()
  const [count, setcount] = useState(0)
  const [newstring, setnewstring] = useState()
  let productsData=[]
  let mobilenumber = cartdata?.state?.contactNo
  const storetype = localStorage.getItem('store_type')
  useEffect(() => {
    let nstring = generateBarCode()
    setnewstring(nstring)
  }, [])
  function generateBarCode() {
    // var nric = $('#text').val();
    let rString = randomString(6, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    return rString
  }
  function randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  //  console.log('cartdata',cartdata)
  const verifyOTP = () => {
    console.log('sfvsfff')
    let resendotp = localStorage.getItem('otp')
    let message = "Hello there! Your order has been placed successfully. Thanks for placing an order!"
    // let sendData = cartdata?.state?.cartData
    let itemname = []
    let itemQuantity = []

    // console.log('resendotpresendotp', resendotp)
    if (cartdata.state.message === parseInt(otp) || parseInt(otp) === parseInt(resendotp)) {
      otpSuccess()
      let randomorderId = randomString(6, '0123456789')
      let totalprice= cartdata.state.totalPrice
      let deliveryCharge =cartdata.state.deliveryCharge
      let totaltax=cartdata.state.totaltax
      let deliveryGate=sessionStorage.getItem('deliveryLocation')
      cartdata?.state?.cartData?.map((item)=>{
        return productsData?.push({
                      name:item.en.name,
                      description:item.en.description,
                      productId:item.productId,
                      quantity:item.count,
                      price:item.price
                     })
       })
      let checkoutData = cartdata?.state?.cartData
      let sendData=cartdata.state
      if(storetype==='hms'){
        orderbookingtwo(randomorderId,sendData).then((res) => {
          // console.log('Order Booking Response', res)
          if (res.data.status === 200 || res.data.status === '200') {
            navigate('/redirecting', {state:{randomorderId,sendData}})
          }
        }).catch((err) => {
          console.log('Error in Order Booking', err)
          Bookingerror()
        })
      } 
      else{
        orderbooking(randomorderId,cartdata.state,productsData,totalprice,deliveryCharge , cartdata.state.statetwo.pickupId,cartdata.state.locationName,totaltax).then((res) => {
          // console.log('Order Booking Response', res)
          if (res.data.status === 200 || res.data.status === '200') {
            sessionStorage.setItem('deliveryLocation', null)
            navigate('/checkout', { state: {checkoutData,cartdata,orderId: randomorderId , productsData:productsData,totalprice:totalprice,deliveryCharge:deliveryCharge,deliveryGate:deliveryGate,totaltax:totaltax ,pin:randomorderId.substr(-4)} })
          }
        }).catch((err) => {
          console.log('Error in Order Booking', err)
          Bookingerror()
        })
      } 

    }
    else {
      console.log('Enter Valid OTP')
      otpError()
      console.log('resendotp', resendotp)
    }

  }


  const dispatch = useDispatch()

  const { t } = useTranslation()


  const handleresendOtp = () => {
    let messageotp = Math.floor(100000 + Math.random() * 900000)
    localStorage.setItem('otp', messageotp)
    cartdata.state.message = ''
    sendMessage(parseInt(cartdata.state.contactNo), messageotp)
      .then(res => {
        console.log('OTP Send Success', res)
        if (res.data.status === 200 || res.data.status === "200") {
          otpresend()
        }
      })
      .catch((err) => {
        console.log('Error in send OTP', err)
        otpError()
      })
  }
  const handlecartedit = () => {
    navigate(`/storelist/${cartdata?.state?.statetwo?._id}`, {state: {item:cartdata?.state?.statetwo} })
  }
  return (
    <>
      <div className='MainWhole-wrapper-container'>
        <Header />
           {/* {console.log('cartdatacartdata',cartdata)} */}
        <div className='CartPage-Main-wrapper'>
          <h2>
            Enter Verification Code
          </h2>
          <h5 className='CartPage-Main-wrapper_h5'>
            {/* {t('cart_page_sub_heading')} */}
            We sent the code to your mobile number- <br /> <b>*** ***{cartdata.state.contactNo.substr(-5)}</b>
          </h5>
          {/* {console.log('cartdatacartdata',cartdata)} */}

          <div className="CartPage-content-wrapper">

            <div className="DetailsPage-content-wrapper-inner">
              {/* <InputLabel className='detailspage-content-label'> {t('cart_page_Enter_otp')} <sup style={{color:"red"}}>*</sup></InputLabel> */}
              <OTPInput value={otp} onChange={setOtp} autoFocus OTPLength={6} otpType="number" disabled={false} className="Cart_page_otp_input" />
            </div>
            <div className='Cart_page_Resend_button_wrapper'>
              <p> Didn't get the code?</p>

              <button onClick={() => handleresendOtp()}> Resend Code</button>
            </div>

          </div>

          {/* <div className="DetailsPage-content-wrapper">
               <h2 className="CartPage-Product-wrapper-h2">
               {t('cart_page_View_Cart')}

               </h2>
                  {cartdata?.state?.cartData?.map((item)=>{
                    if(item.count>0){
                     return (  <div className="ItemsListPage-ItemsList-containers">       
                     <div className='product-content-wrapper'> 
                                     <div className='product-content-wrapper-inner-div-one'> 
                                     <img src={item.image} className='product-images-class'/>
                                     </div>
                                     <div className='product-content-wrapper-inner-div-two'>
                                         <h4> {item.title}</h4>
                                         <p>€{item.price}</p>
                                     </div>
                     </div>
                 <div className='ItemsListPage-ItemsList-Counter-wrapper'> 
                   <button className='ItemsListPage-ItemsList-Counter-btn' onClick={()=>{ dispatch(DecrementCount(item, item.id))}
                 }> -</button>
                   <input type="number" readOnly value={item.count} className="ItemsListPage-ItemsList-Counter-input"/> 
                   <button className='ItemsListPage-ItemsList-Counter-btn' onClick={()=>{ dispatch(IncrementCount(item, item.id))}}> +</button>  
                 </div>
              </div>)}
                  })}  
            </div>  */}
          <div className="BillDetails_page_BillTotal_wrapper">
           {storetype==='self'&& <div className='d_flex_center'>
              <h3>Cart Details</h3>
              {/* <img src="/assets/images/edit-cart.svg" className='EditIcon_image' onClick={handlecartedit} style={{ cursor: 'pointer' }} /> */}
              {/* <EditOutlinedIcon onClick={handlecartedit} sx={{cursor:'pointer'}}/> */}
            </div>} 
            <div className="DetailsPage-content-wrapper">
            {storetype==='self'&& <>{cartdata?.state?.cartData?.map((item , index) => {
                return (
                  <div key={index} className='d_grid_column BillDetails_item_total_wrapper'>
                    <div className="text_align_start"> <p> {item.en.name}</p></div>
                    <div className="text_align_end"> <h4> X{item.count}</h4></div>
                  </div>)
              })}</>} 
              <div className='d_grid_column BillDetails_item_topay_wrapper'>
                <div className="text_align_start"> <p> Selected Gate:</p></div>
                <div className="text_align_end"> <h4> {cartdata.state.locationName}</h4></div>
              </div>
            </div>
          </div>
          <div className="Details-page-Nextbutton-wrapper">

            {isorderplace ? <button type="submit" disabled={true} style={{ cursor: 'pointer' }} onClick={() => verifyOTP()}> Verify OTP<span>  <ArrowForwardIosIcon sx={{ position: 'absolute', right: '12px', top: '20px' }} /></span></button> :
              <button type="submit" style={{ cursor: 'pointer' }} onClick={() => verifyOTP()}> Verify OTP <span>  <ArrowForwardIosIcon sx={{ position: 'absolute', right: '12px', top: '20px' }} /></span></button>
            }
          </div>
        </div>
        <ToastContainer />
      </div>
      <Footer />
    </>
  )
}

export default Cart