import { BottomNavigation, BottomNavigationAction, Link } from '@mui/material'
import React, { Fragment } from 'react'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ProductPageHeader from './ProductPageHeader'
import Rating from '@mui/material/Rating';
import { DecrementCount, IncrementCount } from '../redux/Actions'
import { productlistdata } from '../Data/Storelist'
import { useDispatch, useSelector } from 'react-redux'
import MenuIcon from '@mui/icons-material/Menu';
import Footer from './Footer'
import { useEffect } from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { getproductlist } from '../API'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Productlist(props) {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: '#ffffff',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    paddingTop: '11px',
    border: 'none',
    paddingRight: '0px',
    paddingLeft: '0px',
    zIndex: '999999999999999999999999'
  }
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const storedata = useLocation()
  let lastdata = []
  const dispatch = useDispatch()
  const [changestate, setchanegState] = useState(false)
  const { cart, totalitems, totalPrice } = useSelector(state => state.IC)
  // console.log("totalitems", totalitems)
  const [productlist, setproductlist] = useState()
  const [newproductlist, setnewproductlist] = useState()
  const [totalprice, settotalprice] = useState(0)
  const [totalcount, settotalcount] = useState(0)
  let countarray = []
  let secondlastdata = []
  const [thirdlastdata, setthirdlstdata] = useState()
  let groupedData
  const [groupedArray, setgroupedArray] = useState()

  useEffect(() => {
    getproductlist(storedata?.state?.item?.storeId).then((res) => {
      // console.log('Product list Response', res)
      setproductlist(res.data.message)
    }).catch((err) => {
      // console.log("Error in product list response", err)
    })
  }, [changestate])

  useEffect(() => {
    productlist?.map((item) => {
      item.count = 0
    })
    secondlastdata = localStorage.getItem('lastdata')
    if (secondlastdata) {
      try {
        setthirdlstdata(JSON.parse(secondlastdata))
      } catch (error) {
        console.error('Error parsing stored array:', error);
      }
    }
    groupedData = productlist?.reduce((accumulator, product) => {
      if (!accumulator[product.category]) {
        accumulator[product.category] = [];
        // Create a new category if it doesn't exist
      }
      accumulator[product.category].push(product); // Add the product to the appropriate category
      return accumulator;
    }, {})
    if (groupedData) {

      // setgroupedArray(Object.entries(groupedData))
          
      const sandwichesIndex = Object.entries(groupedData)?.findIndex(item => item[0] === "Sandwiches");

      // If "Sandwiches" category exists, move it to the top
      if (sandwichesIndex !== -1) {
        const sandwichesCategory = Object.entries(groupedData)?.splice(sandwichesIndex, 1)[0];
        Object.entries(groupedData)?.unshift(sandwichesCategory);
      }
  
      // Sort the remaining categories alphabetically
      setgroupedArray(Object.entries(groupedData)?.sort((a, b) => b[0].localeCompare(a[0])))   
    }
  }, [productlist])

  
  useEffect(() => {
    if (thirdlastdata?.length > 0) {
      setproductlist(productlist?.map(itemA => {
        const matchingObject = thirdlastdata?.find(itemB => itemB._id === itemA._id);
        if (matchingObject) {
          return { ...matchingObject };
        }
        return itemA;
      }))
    }
    // console.log('productlist', productlist)
  }, [])

  useEffect(() => {
    let totalpricearr = []
    lastdata.map((item) => {
      totalpricearr.push(item.price * item.count)
    })
    settotalprice(totalpricearr.reduce((a, b) => a + b, 0))
    // console.log('lastdata',lastdata)
    lastdata.map((item) => {
      countarray.push(item.count)
    })

    settotalcount(countarray.reduce((a, b) => a + b, 0))

  }, [lastdata, cart])


  const handleClose = (reason) => {
    if (reason && reason == "backdropClick")
      return;
    setOpen(false);
  }
  const navigate = useNavigate()
  const handleNextButton = () => {
    //  navigate('/selectDetails', {state:lastdata}) 
    sessionStorage.setItem('nextpage', true)
    localStorage.setItem('lastdata', JSON.stringify(lastdata))
    navigate('/billdetails', { state: { stateone: lastdata, statetwo: storedata.state.item, statethree: storedata?.state?.productlist } })
  }
  // console.log('storedata',storedata)
  return (
    <div className='MainWhole-wrapper-container'>
      <ProductPageHeader storedata={storedata} open={open} />
      <div className='Productlist_page_content_wrapper'>
        {/* {console.log('groupedArray',groupedArray)} */}


        <div className='Productlist_page_productlist_wrapper'>
          {groupedArray?.map((itm, index) => {
            return (
              <Fragment key={index}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ margin: '' }}
                  >
                    <Typography > <p className='Productlist_page_accordian_heading'>
                      {itm[0]}

                    </p></Typography>
                  </AccordionSummary>

                  {itm[1]?.map((items ,index) => {
                    if(items.status===0){
                      return  <div key={index} className="Productlist_page_individual_product" id={items.category}>

                          <div className="Productlist_page_individual_product_content_wrapper">
                            <h2> {items.en.name}</h2>
                            <p className='Productlist_page_price_p'>₹{items.price} </p>
                            <div className='Productlist_page_rating_div'>
                              <Rating name="read-only" value={items.rating} readOnly /> <span> {items.rating}</span>
                            </div>
                            <p> {items.en.description}</p>
                          </div>
                          {/* {console.log('counter', cart)} */}
                          <div className="Productlist_page_individual_product_image_wrapper">
                            <img src={items.image} />
                            <div className='Productlist_product_counter_wrapper'>
                              {(cart[items._id] === 0 || !cart.hasOwnProperty(items._id)) ?
                                <button onClick={() => {
                                  let countnumber = countarray.reduce((a, b) => a + b, 0)
                                  if (totalcount >= 4) {
                                    // totalitems
                                    handleOpen()
                                  }
                                  else {
                                    handleClose()
                                    items.addbtn = true
                                    setchanegState(!changestate)
                                    dispatch(IncrementCount(items, cart, totalPrice))
                                  }
                                }}> Add
                                  {/* {console.log('items.addbtn', items.addbtn)} */}
                                </button> : <>
                                  {/* {console.log('items._id', items._id)} */}
                                  <button className='ItemsListPage-ItemsList-Counter-btn' onClick={() => {
                                    dispatch(DecrementCount(items, cart, totalPrice))
                                  }}> - </button>
                                  <input type="number" style={{ WebkitAppearance: 'none', margin: 0 }} readOnly value={cart[items._id]} className="ItemsListPage-ItemsList-Counter-input" />
                                  <button className='ItemsListPage-ItemsList-Counter-btn' onClick={() => {
                                    let countnumber = countarray.reduce((a, b) => a + b, 0)
                                    if (totalcount >= 8) {
                                       // totalitems
                                      handleOpen()
                                    }
                                    else {
                                      handleClose()
                                      dispatch(IncrementCount(items, cart, totalPrice))
                                    }
                                  }}> + </button>  </>
                              }
                              
                            </div>
                          </div>
                          {(() => {
                            if (cart[items._id] > 0) {
                              items.count =cart[items._id]
                              lastdata.push(items)
                            }
                            else if (cart[items._id] <= 0) {
                              lastdata = lastdata.filter(item => items._id !== item._id)
                              items.addbtn = false
                            }
                          })()}
                          {/* {console.log('cart[items._id]', cart[items._id])} */}
                          </div>
                    }
                  })}
                  <AccordionDetails className='HelpPage_Accordian_Details_wrapper'>
                  </AccordionDetails>
                </Accordion>
              </Fragment>
            )
          })}

        </div>
      </div>
      <div className='Productlist_page_menu_icon_wrapper'>
        <MenuIcon sx={{ fontSize: '40px' }} />
      </div>
        {lastdata.length > 0 &&
        <div className="ProductList_page_next_button_wrapper">
          <div className="ProductList_page_next_button_wrapper_inner" onClick={handleNextButton}>
            {/* NEXT  <ArrowForwardIosIcon sx={{position: 'absolute',right: '12px',top: '20px'}}/> */}
            <div> <p>  {totalcount} items | ${totalprice.toFixed(2)}</p> </div>
            {/* totalPrice */}
            <div> View Cart </div>
          </div>
        </div>}
      {/* {console.log('lastdata',lastdata)} */}
      <Modal
        open={open}

        onHide={handleClose}

        backdrop="static"
        keyboard={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="Sorry_div_wrapper">
            <div className='Sorry_div_wrapper_inner_div' onClick={handleClose}>
              <HighlightOffIcon style={{ fontSize: '35px', margin: '5px' }} />
              <p style={{ fontSize: '17px' }}>Close</p>
            </div>
            <img src="/assets/images/Emoji_logo.png" />
            <h4> Sorry !!!</h4>
            <p className='Sorry_div_wrapper_p'>  Cannot add more items. Weight/volume limit reached.</p>
            {/* <button className="Sorry_div_wrapper_next_button" onClick={handleNextButton}> View Cart</button> */}
          </div>
        </Box>
      </Modal>
      <Footer />
    </div>
  )
}

export default Productlist
