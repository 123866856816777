import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import { useTranslation } from "react-i18next";
import Footer from './Footer';
import HelpPageHeader from './HelpPageHeader';
import { Link } from 'react-router-dom';


function Help() {

  const navigate= useNavigate()  

  const {t}= useTranslation() 
  return (
    <div className='MainWhole-wrapper-container'>   
       <HelpPageHeader/>
      <div className='Help-Page-Main-content-wrapper'>
      <div className='Help-page-Header-div'>
         <div className='Help-page-Header-inner-div-one'>
          <h4 className='Help-page-Header-div-h4'> 
         {/* {t('Help_page_Main_heading')} */}
         Help
         </h4>
         </div>
       
     </div>
     <div className='HelpUs_page_accordian_content_wrapper'>
     <Accordion style={{marginTop:'8px'}} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{  margin:''}}
           >
          < > <p className='Help_page_content_heading'>
           {/* {t('Help_page_accordian_heading_one')}  */}
           FAQ
           </p></>
          </AccordionSummary>
          <AccordionDetails className='HelpPage_Accordian_Details_wrapper'>
           <>
                 <div className='Help_page_content_single_content_wrapper'> 
                  <h3 className='Help_page-content-h3'> <span>Q1.</span> What is orderatcvg.com Customer Care Number?</h3>
                  <p className='Help_page-content-p'> We value our customer’s time for quick and easy resolution. You can also email us your issue at support@orderatcvg.com We will revert within 24-48 hrs</p>
                  <div className='Help_page_content_button_div_wrapper'> 
                  <a href="mailto:support@ottonomy.io">
                  <button className='Help_page_content_button'> Send Email</button></a>
                  <p className='Help_page-content-p_two'> We will revert within 24-48 hrs </p>
                </div>
                </div>  
                <div className='Help_page_content_single_content_wrapper'> 
                <h3 className='Help_page-content-h3'> <span>Q2.</span> Can I edit my order?</h3>
                <p className='Help_page-content-p'> Your order can be edited before the payment gateway. Once an order is placed, you cannot edit the order.</p>
                </div>
                <div className='Help_page_content_single_content_wrapper'> 
                <h3 className='Help_page-content-h3'> <span>Q3.</span> What is the refund Policy?</h3>
                <p className='Help_page-content-p'> If for any reason, the company is not able to deliver your goods, we will reimburse You no later than 14 days from the day on which We receive the returned Goods. We will use the same means of payment as You used for the Order, and You will not incur any fees for such reimbursement.
                                                </p> 
                <p className='Help_page-content-p'>    In order to get an update regarding the status of your refund you can email us at: <a className='Help_page-content-p_two' href="mailto:support@ottonomy.io">support@ottonomy.io</a></p>
                </div>
         </>
        </AccordionDetails>
      </Accordion>
       <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{  margin:''}}
           >
          < > <p className='Help_page_content_heading'>
           {/* {t('Help_page_accordian_heading_one')}  */}
           Privacy Policy
           </p></>
          </AccordionSummary>
          <AccordionDetails className='HelpPage_Accordian_Details_wrapper'>
           <>
             <h3 className='Help_page-content-h3'> INTRODUCTION </h3>
             <p className='Help_page-content-p'> Ottonomy, Inc. (“Ottonomy,” “we,” “us,” or “our”) respects the privacy of its Users (“User,” “your,” or “you”). This Privacy Policy (the “Privacy Policy”) explains how we collect, use, disclose, and safeguard your information when you use Ottonomy Platform (the “Platform”) through Ottonomy’s website at https://www.ottonomy.io/ (the “Website”). </p>
             <p className='Help_page-content-p'> Ottonomy is committed to protecting the privacy of its Users whose information is collected and stored while using Ottonomy’s Platform through our Website. This Privacy Policy is applicable to our Website, and Platform.  </p>
             <p className='Help_page-content-p'> The capitalized terms have the same meaning as ascribed in our Terms of Use or Terms of Service as applicable, unless otherwise noted here. </p>
             <p className='Help_page-content-p'> PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND OUR POLICIES AND PRACTICES REGARDING YOUR INFORMATION AND HOW WE WILL TREAT IT. BY ACCESSING OR USING OUR WEBSITE AND PLATFORM, YOU AGREE TO ACCEPT ALL THE TERMS CONTAINED IN THIS PRIVACY POLICY AND ACKNOWLEDGE AND AGREE WITH THE PRACTICES DESCRIBED HEREIN. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS AND USE OUR WEBSITE AND PLATFORM </p>
             <p className='Help_page-content-p'>IF YOU HAVE ANY QUESTIONS REGARDING THIS PRIVACY POLICY, PLEASE SEND US AN EMAIL AT INFO@OTTONOMY.IO.  </p>
             <p className='Help_page-content-p'> WE DO NOT SELL YOUR PERSONAL INFORMATION, NOR DO WE INTEND TO DO SO. WE DO NOT GIVE ACCESS TO YOUR PERSONAL INFORMATION TO THIRD PARTIES EXCEPT TO SUBPROCESSORS TO ASSIST US IN THE PROVISION OF OUR SERVICES TO YOU.  </p>
              <h3 className='Help_page-content-h3'> WHAT INFORMATION DO WE COLLECT? </h3>
             <div className='Help_page-content-p'> 
             When you register to use our Website or Platform, we collect personal information (also referred to as personally identifiable information or “PII”) which may include your name, address, online contact information such as your email address or username, phone number, and other personal information. The information so collected will be stored on our servers.
               <ul><li>
                Geolocation and Equipment Information.  We may collect information that does not personally identify you such as (i) your geolocation, and (ii) information about your internet connection, the equipment you use to access our Website or Platform, and usage details.
               </li>
               <li> Financial Information. We currently do not collect or store any credit cards or bank information, as we are using a third-party payment processor.  However, we will update this Privacy Policy when we start using and storing such information. We will also inform you via reasonable means if we start collecting such information from you.</li>
               </ul>
              </div>
              <h3 className='Help_page-content-h3'> HOW DO WE COLLECT INFORMATION?</h3>
             <p className='Help_page-content-p'>   We collect personal information from you in the following ways: </p>
             <div className='Help_page-content-p'>
                  <ul><li>At registration on our Website or Platform;</li>
                    <li> In email, text, and other electronic messages between you and our Website or Platform;</li>
                    <li> Through mobile and desktop applications your downloads from our Website or Platform, which provides dedicated non-browser based interaction between you and our Website or Platform; </li>
                    <li> When you interact with our advertising and applications on third-party website and services, if those applications or advertising include a link to this Privacy Policy; </li>
                    <li>From you placing an order, which includes details of transactions you carry out on our Website or Platform;</li>
                    <li> When you subscribe to a newsletter; </li>
                    <li> From your responses to a survey; </li>
                    <li> From forms filled out by you; </li>
                    <li> From records or copies of correspondences (including email addresses) if you contact us; </li>
                    <li> From search queries on our Website or Platform; and</li>
                    <li> When you post information to be published or displayed on our Website or Platform. </li>
                    </ul>
                    </div>
               <p className='Help_page-content-p'> We collect information from you automatically when you navigate through our Website or Platform in the following ways:</p>
               <div className='Help_page-content-p'>
                  <ul> 
                      <li> Usage details; </li>
                      <li>IP addresses;</li> 
                      <li> Information obtained through browser cookies; </li>
                      <li> Information obtained through flash cookies; </li>
                      <li>Web beacons on our Website; </li>
                      <li>Web beacons on emails sent by us; and</li>
                      <li> Other tracking technologies. </li>
                  </ul>
                  </div>
                  <h3 className='Help_page-content-h3'> HOW DO WE COLLECT INFORMATION?</h3>
                  <p className='Help_page-content-p'> We use the information that you provide to:</p>     
                  <div className='Help_page-content-p'>
                  <ul> <li> Personalize your experience in using our Platform; </li>
                         <li> Provide you with information, products, or services requested from us;</li>
                         <li> Present our Website and Platform and their contents to you;</li>
                         <li> Provide you with notices about account and/or subscription, including expiration and renewal notices;</li>
                         <li> Carry out obligations and enforce rights arising from contracts entered into between you and us, including billing and collection; </li>
                         <li>  Notify you about changes to our Website and Platform;</li>
                         <li> Allow you to participate in interactive features on our Website and Platform; </li>
                         <li> Improve the Website and Platform;</li> 
                         <li> Improve our customer service;</li>
                         <li> Administer contests, promotions, and surveys or other Website and Platform features; </li>
                         <li> Process transactions;</li>
                         <li> Anonymize data and aggregate data for statistics;</li>
                         <li> Contact you for other purposes with your consent;</li>
                         <li>Contact you about our products and services that may be of interest; </li>
                         <li> Contact you about third parties’ goods and services; </li>
                         <li>Enable the display of advertisements to our advertisers’ target audiences, although personal information is not shared with advertisers without your consent; and</li></ul>
                     </div>
                     <h3 className='Help_page-content-h3'> OUR COOKIE POLICY</h3>  
                     <p className='Help_page-content-p'> Cookies are small pieces of text used to store information on web browsers. Cookies are used to store and receive identifiers and other information on computers, phones, and other devices. Other technologies, including data we store on your web browser or device, identifiers associated with your device, and other software, are used for similar purposes. In this Privacy Policy, we refer to all of these technologies as “Cookies.” </p>
                     <p className='Help_page-content-p'> We use Cookies on our Website to (a) help remember and process items in the shopping cart, (b) understand and save your preferences for future visits, (c) keep track of advertisements, (d) compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future, and (e) allow trusted third-party services that track this information on our behalf.  You can set your browser to refuse all or some browser Cookies, but it may affect your user experience. We honor Do Not Track signals and, if one is in place, we will not track, plant cookies, or use advertising</p>  
                     <p className='Help_page-content-p'>  We allow third party behavioral tracking and links to third-party web pages.  Occasionally, at our discretion, we may include or offer third-party products or services on our Website or Platform.  These third-party sites have separate and independent privacy policies.  We, therefore, have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our Website, App, or Platform and welcome any feedback at about these sites.  </p>
                  <h3 className='Help_page-content-h3'> DATA SECURITY MEASURES.</h3>
                   <div className='Help_page-content-p'> <ul><li>Security Measures.  We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls.  The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website or Platform, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website or Platform. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on our Website or Platform. </li>
                         <li>Fair Information Practice Principles.  In the event of a personal data breach, we will notify you within three (3) days via (i) email and/or (ii) our Platform notification system on our Website and/or App.  We agree to the individual redress principle, which requires that individuals have a right to pursue legally enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that that individuals have recourse to courts or a government agency to investigate and/or prosecute non-compliance by data processors.</li>
                    </ul> </div>
                    <h3 className='Help_page-content-h3'>   DISCLOSURE OF PERSONAL INFORMATION</h3>
                    <p className='Help_page-content-p'> There are times when we may share Personal Information that you have shared with us may be shared by Ottonomy with others to enable us to provide you over Services, including contractors, service providers, and third parties (“Partners”). This section discusses only how Ottonomy may share such information with Partners. We will ensure that our Partners protect your Personal Information. The following describe how and with whom we may share your Personal Information:  </p>
                      <h6 className='Help_page-content-h6'> Disclosure of Personal Information.</h6>
                     <div className='Help_page-content-p'>
                        <ul>      
                          <li> We may disclose aggregated, de-personalized information about you that does not identify any individual to other parties without restriction, such as for marketing, advertising, or other uses. </li>  
                          <li> We may disclose personal information to our subsidiaries and affiliates.</li>
                          <li> We may disclose personal information to contractors, services providers, and other third parties. </li>
                          <li> We require all contractors, service providers, and other third parties to whom we disclose your personal information to be under contractual obligations to keep personal information confidential and to use it only for the purposes for which we disclose them.</li>
                          <li>We may disclose personal information in the event of a merger, sale of business, etc.</li>
                          <li>We may disclose to third parties to market their products and services to you if you have either consented or not opted out of these disclosures. </li>
                          <li> We may disclose personal information to third parties to market their products and services if you have either consented or not opted out of these disclosures. </li>
                          <li> We require all other Partners, to whom we disclose your personal information, to enter into contracts with us to keep personal information confidential and use it only for the purposes for which we disclose it to such Partners. </li>
                          <li>  We may disclose personal information for any other purpose for which you have provided it.</li>
                          <li> We may only disclose personal information as described in this Privacy Policy or your consent. </li>
                          <li> We sell personal information to others for their use.</li>
                        </ul>
                    </div>
                    <h6 className='Help_page-content-h6'> Other Disclosure of Personal Information</h6>
                     <div className='Help_page-content-p'>
                        <ul>      
                          <li> We will disclose personal information (i) to comply with any court order, law, or legal process, including to respond to any government or regulatory request, (ii) to enforce or apply our Terms of Use or Terms of Service and other agreements, including for billing and collection purposes, (iii) if we believe it is necessary or appropriate to protect the rights, property, or safety of Ottonomy, our customers or others, and/or (iv) if it is necessary or appropriate to protect the rights, property, or safety of Ottonomy, our customers, or others, and this includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction. </li>  
                        </ul>
                    </div>
                    <h6 className='Help_page-content-h6'> Third Party Disclosure.</h6>
                    <div className='Help_page-content-p'>
                        <ul>      
                          <li>   We do not sell, trade, rent, or otherwise transfer personal information to others, unless we provide you with advance notice.  This does not include our hosting partners and other parties who assist us in operating our Website or Platform, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. </li>  
                          <li>   We do not provide non-personally identifiable visitor information for marketing purposes. </li>  
                        </ul>
                    </div>
                    <h6 className='Help_page-content-h6'> Choices Users Have About How Ottonomy Uses and Discloses Information. </h6>
                    <div className='Help_page-content-p'>
                        <ul>      
                      <li>  Tracking Technologies and Advertising. You can set their browser to refuse some or all the browser cookies, but if you disable or refuse cookies, some parts of our Website may not be accessible or function properly. </li>
                      <li>  Disclosure of Users’ Information for Third-Party Advertising.  Users can opt-out by (i) checking the relevant form when we collect the data; (ii) logging into the Website or Platform and adjusting their preferences in their account profile by checking or unchecking the relevant boxes, or (iii) emailing us their opt-out request at info@ottonomy.io. Users receiving promotional email can opt-out by sending a return email requesting to be omitted from future promotional email distributions. This opt-out will not apply to information provided by Ottonomy for product purchases, warranty registration, or other transactions.    </li>
                      <li>  Disclosure of User’s Information for Targeted Advertising.  Users can opt-out by (i) checking the relevant form when we collect the data, (ii) logging into the Website or Platform and adjusting their preferences in their account profile by checking or unchecking the relevant boxes, or (iii) emailing us their opt-out request at info@ottonomy.io.</li>
                        </ul>
                    </div>
                    <h3 className='Help_page-content-h3'> YOUR CALIFORNIA PRIVACY RIGHTS</h3>
                  <p className='Help_page-content-p'> Ottonomy does not sell, trade, or otherwise transfer to outside third parties your “Personal Information” as the term is defined under the California Civil Code Section § 1798.82(h).  Additionally, California Civil Code Section § 1798.83 permits Users of our Website or Platform that are California residents to request certain information regarding our disclosure of their Personal Information to third parties for their direct marketing purposes. To make a request for such disclosure, or identification and/or deletion of Personal Information in all our systems that we store on you, please send an email to info@ottonomy.io or write us at Ottonomy, 1032 2nd St, Santa Monica, CA 90403.</p> 
                  <p className='Help_page-content-p'> Note that (i) if we delete your Personal Information as requested, we will no longer be able to provide our services to you and (ii) we may need to keep such Personal Information for a while during the shutting down and billing process. If you would like to discuss our Personal Information storage and processing process with us, please send us an email at to info@ottonomy.io or write us at Ottonomy, 1032 2nd St, Santa Monica, CA 90403. </p>
                  <h3 className='Help_page-content-h3'> MODIFICATIONS TO OUR PRIVACY POLICY</h3>
                  <p className='Help_page-content-p'>Ottonomy reserves the right, at its sole discretion, to change or modify this Privacy Policy at any time. In the event we modify this Privacy Policy, such modifications shall be binding on you only upon your acceptance of the modified Privacy Policy. We will inform you about the modifications on our Privacy Policy page via email, on our Website or Platform by posting a modified version of the Privacy Policy page, or by a comparable means within a reasonable time period.  Your continued use of our Website or Platform shall constitute your consent to such changes.</p>
                  <h3 className='Help_page-content-h3'> THIRD PARTY PROCESSORS</h3>
                  <p className='Help_page-content-p'>Our selected partners and service providers may process personal information about you on our behalf as described below:</p>
                  <p className='Help_page-content-p'>“Digital Marketing Service Providers
                    We periodically appoint digital marketing agents to conduct marketing activity on our behalf, such activity may result in the compliant processing of personal information.
                    Our appointed data processors include:
                    (i)Prospect Global Ltd (trading as Sopro) Reg. UK Co. 09648733. You can contact Sopro and view their privacy policy here: http://sopro.io. Sopro are registered with the ICO Reg: ZA346877 their Data Protection Officer can be emailed at: dpo@sopro.io.”</p>
                    <h3 className='Help_page-content-h3'>   CONTACT US</h3>
                    <p className='Help_page-content-p'>To ask questions or comment about this Privacy Policy and our privacy practices, contact us at:</p> 
                    <div className='Help_page-content-p'>
                      <ul> <li>
                            Hardik Sharma </li>
                            <li> Email: hardik.sharma@ottonomy.io</li>
                            <li>  Address:  Ottonomy, 1032 2nd St, Santa Monica, CA 90403</li>
                       </ul>
                    </div>
                     <p className='Help_page-content-p'> 
                        PLEASE NOTE: IF YOU USE OUR WEBSITE OR PLATFORM, YOU HAVE AGREED TO AND ACCEPTED THE PRACTICES DESCRIBED IN THIS PRIVACY POLICY AND THE TERMS AND CONDITIONS SET FORTH IN OUR TERMS OF USE OR OUR TERMS OF SERVICE, AS APPLICABLE.  IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY OR OUR TERMS OF SERVICE, PLEASE DO NOT USE OUR WEBSITE OR PLATFORM.
                   </p>
           </>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{ margin:''}}
        >
    <><p className='Help_page_content_heading'> {t('Help_page_accordian_heading_two')} </p></>
        </AccordionSummary>
        <AccordionDetails className='HelpPage_Accordian_Details_wrapper'>
          <>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_1')}</p>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_2')}</p>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_3')}</p>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_4')}</p>
         <h6 className='Help_page-content-h6'> {t('Help_page_accordian_2_content_h6_1')}</h6> 
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_5')}</p> 
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_6')}</p>
         <h6 className='Help_page-content-h6'> {t('Help_page_accordian_2_content_h6_2')}</h6>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_7')}</p>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_8')}</p>
          <ul>
             <li className='Help_page-content-p'>{t('Help_page_accordian_2_content_p_9')}</li>
             <li className='Help_page-content-p'>{t('Help_page_accordian_2_content_p_10')}</li>
             <li className='Help_page-content-p'>{t('Help_page_accordian_2_content_p_11')}</li>
             <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_12')}</li>
          </ul>
            <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_13')}</p>
            <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_14')}</p>
            <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_15')}</p>
          <ul> 
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_16')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_17')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_18')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_19')}</li>
          </ul>
            <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_20')}</p>
          <h6 className='Help_page-content-h6'> {t('Help_page_accordian_2_content_h6_3')}</h6>
            <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_21')}</p>
          <ul> 
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_22')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_23')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_24')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_25')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_26')}</li>
          </ul>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_27')}</p>
           <p className='Help_page-content-p'>  {t('Help_page_accordian_2_content_p_28')}</p>
          <ul> 
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_29')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_30')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_31')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_32')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_33')}</li>
            <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_34')}</li>
            <li className='Help_page-content-p'>{t('Help_page_accordian_2_content_p_35')}</li>
          </ul>
           <p className='Help_page-content-p'>{t('Help_page_accordian_2_content_p_36')}</p>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_37')}</p>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_38')}</p>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_39')}</p>
          <ul>
             <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_40')}</li>
             <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_61')}</li>
             <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_62')}</li>
          </ul>
          <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_63')}</p>
          <h6 className='Help_page-content-h6'> {t('Help_page_accordian_2_content_h6_4')}</h6>
            <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_64')}</p>
         <h6 className='Help_page-content-h6'> {t('Help_page_accordian_2_content_h6_5')}</h6>
            <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_65')}</p>
         <h6 className='Help_page-content-h6'> {t('Help_page_accordian_2_content_h6_6')}</h6>
            <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_66')}</p> 
         <h6 className='Help_page-content-h6'> {t('Help_page_accordian_2_content_h6_7')}</h6>
            <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_67')}</p>
         <h6 className='Help_page-content-h6'> {t('Help_page_accordian_2_content_h6_8')}</h6>
            <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_68')}</p>
         <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_69')}</p>
          <h6 className='Help_page-content-h6'> {t('Help_page_accordian_2_content_h6_9')}</h6>
           <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_70')}</p>
           <ul>
             <li className='Help_page-content-p'>{t('Help_page_accordian_2_content_p_71')} </li>
             <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_72')}</li>
             <li className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_73')}</li>
             <li className='Help_page-content-p'>{t('Help_page_accordian_2_content_p_74')} </li>
          </ul>
          <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_75')}</p>
          <p className='Help_page-content-p'> {t('Help_page_accordian_2_content_p_76')}</p>
           </>
         </AccordionDetails>
      </Accordion>
      </div>  
    </div>

    <Footer/>
    </div>
  )
}

export default Help