export const totalItemsCount = (item, cart) => {
  let totalitems = 0

  for (let cartitem in cart) {
    totalitems += cart[cartitem]
  }

  return totalitems
}

export const IncrementCount = (item, cart = {}, totalprice) => async (dispatch) => {
  const id = item._id;
  if (cart.hasOwnProperty(item._id)) {
    cart[id] += 1;
  } else {
    cart[id] = 1;
  }
  let totalitems = totalItemsCount(item, cart)
  totalprice += item.price

  dispatch({
    type: 'INCREMENT_COUNT', payload: {
      cart: cart,
      totalitems: totalitems,
      totalPrice: totalprice
    }
  })
}

// if(action.payload.data.id === action.payload._id)
// {
//   return  {...state, counter: ++action.payload.data.count};
// }
export const DecrementCount = (item, cart = {}, totalprice) => async (dispatch) => {
  const id = item._id;
  if (cart.hasOwnProperty(item._id)) {
    cart[id] -= 1;
  } else {
    delete cart[id];
  }
  let totalitems = totalItemsCount(item, cart, totalprice)
  totalprice -= item.price

  dispatch({
    type: 'DECREMENT_COUNT', payload: {
      cart: cart,
      totalitems: totalitems,
      totalPrice: totalprice
    }
  })
}


export const ItemsList = (data) => {
  return {
    type: "ITEMS_LIST_STORING",
    payload: data
  }
}
export const otpaction = (data) => {
  return {
    type: "OTP_NUMBER",
    payload: data
  }
}

export const paymentaction = (item) => async (dispatch) => {
  dispatch({ type: 'Payment_Refrence', payload :item })
}