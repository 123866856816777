import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements ,CardElement,CardCvcElement,CardNumberElement,CardExpiryElement} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import { orderconfirm } from "../API";

export default function CheckoutForm(props) {

  const toastmsg =(msg)=>toast(msg)
  const stripe = useStripe();
  const elements = useElements();
  let CartData=props.cartData.state
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate=useNavigate();
  //  console.log('CartData',CartData)
  const handleSubmit = async (event) => {
    // console.log('clientSecret', props.clientSecret)
    event.preventDefault();
    const { paymentIntent, error } = await stripe.confirmCardPayment(
       props.clientSecret,
      {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: CartData?.cartdata?.state?.username,
            address: {
              line1: '123 Main St',
              city: 'City',
              state: 'State',
              postal_code: '12345',
              country: 'US',
            }
          }
        },
      }
    );

    if (error) {
      // console.log('Payment failed:', error.message);
      toastmsg(error.message)
    } else {
      // Payment successful, retrieve payment reference from paymentIntent
      const paymentReference = paymentIntent.id;
      // console.log('Payment reference:', paymentReference);
      if(paymentIntent.status=='succeeded'){
        // console.log('props.cardData',props.cartData)
          orderconfirm(props.cartData,paymentReference)
          .then((res)=>{
            toastmsg(res.data.message)
            navigate('/congratulations',{state:props.cartData})
          }).catch((err)=>{
            toastmsg(err.data.message)
          })
          
       
      }
      // Process payment reference or perform other actions
    }
    

  }
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!stripe || !elements) {
  //     // Stripe.js has not yet loaded.
  //     // Make sure to disable form submission until Stripe.js has loaded.
  //     return;
  //   }
  //   setIsProcessing(true);

  //   const { error } = await stripe.confirmPayment({
  //     elements,
  //     confirmParams: {
  //       // Make sure to change this to your payment completion page
  //       return_url: `${window.location.origin}/congratulations` ,
  //     },
  //   }, 
  //   {
  //    metadata:props.cartData.state
  //   }
  //   ).then(function(result) {
  //     if (result.error) {
  //       // Inform the customer that there was an error.
  //       console.log(result.error.message);
  //     } else {
  //       // Handle next step based on PaymentIntent's status.
        
  //       console.log("PaymentIntent ID: " + result.paymentIntent.id);
  //       console.log("PaymentIntent status: " + result.paymentIntent.status);
  //     }
  //   })

   
  
  //   if (error.type === "card_error" || error.type === "validation_error") {
  //     setMessage(error.message);
  //   } else  {
  //     setMessage("An unexpected error occured.");
  //   }

  //   setIsProcessing(false);
  // };

  return (
    <>
    <form id="payment-form" onSubmit={handleSubmit}>
      {/* {console.log('props.cartData',props.cartData)} */}
      {/* <PaymentElement id="payment-element" /> */}
      {/* <CardElement /> */}
              <div className="checkout_page_card_element_wrapper_div"> 
              <p> Card Number</p>
              <CardNumberElement
                className="checkout_page_card_element"
                placeholder="1234 1234 1234 1234"
                id="card_number"
              />
              </div>
              <div className="checkout_page_card_element_wrapper"> 
               <div className="checkout_page_card_element_wrapper_div"> 
               <p> Card Expiry</p>
                <CardExpiryElement
                type="text"
                className="checkout_page_card_element"
                id="card_expire_date" />
              </div>
              <div className="checkout_page_card_element_wrapper_div"> 
              <p> Card CVC</p>
              <CardCvcElement
                className="checkout_page_card_element"
                placeholder="123"
                type="text"/>
              </div>
              </div>
      {/* <button className="Stripe_payment_button" disabled={isProcessing || !stripe || !elements} id="submit">
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
      </button> */}
       <button className="Stripe_payment_button" type="submit">Pay</button> 
      <img className="PowredBY_stripe" src='/assets/images/stripe-powered.svg'/>
      {/* Show any error or success messages */}
       {message && <div id="payment-message">{message}</div>}
    </form>
      <ToastContainer />
      </>
  );
}
