const counterInitialState = {
    counter: 0
}


export const ItemCounter = (state = { cart: {}, totalitems: 0, totalPrice: 0 }, action) => {
    switch (action.type) {
        case 'INCREMENT_COUNT':
            return {
                ...state,
                ...action.payload.cart,
                totalitems: action.payload.totalitems,
                totalPrice: action.payload.totalPrice

            }
        case 'DECREMENT_COUNT':
            return {
                ...state,
                ...action.payload.cart,
                totalitems: action.payload.totalitems,
                totalPrice: action.payload.totalPrice
            }

        default:
            return state;
    }

}


const itemslist = []
export const ItemsListReducer = (state = itemslist, action) => {
    switch (action.type) {

        case "ITEMS_LIST_STORING":
            console.log('state', state, itemslist)
            return { ...state, itemslist: action.payload };

        default:
            return state;
    }

}
const otpnumber = ''
export const otpNumberReducer = (state = itemslist, action) => {
    switch (action.type) {
        case "OTP_NUMBER":
            return { ...state, otpnumber: action.payload };

        default:
            return state;
    }

}
export const paymentReducer = (state = { Payment_refrence: {} }, action) => {
    switch (action.type) {
        case 'Payment_Refrence':
            return {
                ...state,
                Payment_refrence:action.payload
            }
        default:
            return state;
    }

}
