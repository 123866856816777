import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import { getlocationlistmapId } from '../API';
import Header from './Header';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Footer from './Footer';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useSelector } from 'react-redux';
function SelectDelivery() {
  const [deliverylocation, setdeliverylocation] = useState()
  const [activenextbtn, setactivenextbtn] = useState(false)
  const [validation, setvalidation] =useState(false)
  const { t } = useTranslation()
  let alreadySelected = sessionStorage.getItem('deliveryLocation')

  const storetype = localStorage.getItem('store_type')
  useEffect(() => {
    getlocationlistmapId().then((res) => {
      //  console.log('Location List Map Id', res)
      setdeliverylocation(res.data.data)
    }).catch((err) => {
      console.log('Error in Map Id Locations', err)
    })

  }, [])

  const cartData = useLocation()
  const navigate = useNavigate()
  const { cart } = useSelector(state => state.IC)
  const validationSchema = yup.object({
    deliverylocation: yup.string().required('Please Select Delivery location')
  })

  const formik = useFormik({
    initialValues: {
      deliverylocation: alreadySelected ? alreadySelected : '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log('values.deliverylocation',values.deliverylocation,formik.touched.deliverylocation,formik.errors.deliverylocation)
      if(formik.values.deliverylocation ===null || formik.values.deliverylocation ==='null'|| formik.values.deliverylocation ==='' ){
        setvalidation(true)
        return null
      }
      else{
        setvalidation(false)
        sessionStorage.setItem('deliveryLocation', values.deliverylocation)
        if(storetype==='hms'){
          navigate('/details', { state: { locationName: values.deliverylocation , storeId:cartData.state.storeId } })
        }
        else{
          navigate('/details', { state: { locationName: values.deliverylocation, cartdata: cartData.state.stateone, statetwo: cartData?.state?.statetwo ,totalPrice:cartData?.state?.totalPrice,deliveryCharge:cartData?.state?.deliveryCharge , totaltax:cartData?.state?.totaltax} })

        }
      }
     
    }
  })
  let newlocation = []

  const handlecartedit = () => {
    // console.log('Cart Details')
    navigate(`/storelist/${cartData?.state?.statetwo?.storeId}`, {state: {item:cartData?.state?.statetwo} })
  }
  // console.log('cartData',cartData)
  return (
    <>
      <div className='MainWhole-wrapper-container'>
        <Header />
        <div className="DetailsPage-Main-wrapper">
          <h2>
            {/* {t('cart_page_delivery_location')} */}
            Select Delivery Location
          </h2>
          {/* {console.log('cartData',cartData)} */}


          {/* <h3 className="Select_delivery_page_concourse_page_heading"> HMS Host Innovation Lab</h3> */}
          <form onSubmit={formik.handleSubmit}
          // style={{height:'100vh'}}
          >
            <div className='Select_delivery_page_inner_wrapper'>
              {/* <p className='Select_delivery_page_InnerHeading'>COUNCOURSE B</p>  */}
              <div className='Select_delivery_locations_list_wrapper'>
                {/* {deliverylocation?.map((item)=>{
            if(item.locationType ==="DROPOFF" && item.status ==="ACTIVE")
              return (
                <div className={dlocation===item.locationName ? 'Select_delivery_location_delivery_name delivery_active':"Select_delivery_location_delivery_name"} onClick={()=>handlelocationclick(item.locationName)}> 
                   {item.locationName}
                </div> )
        })} */}
                <BottomNavigation
                  showLabels
                  name="deliverylocation"
                  value={formik.values.deliverylocation}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('deliverylocation', newValue)
                    setvalidation(false)
                  }}
                  className='Select_delivery_location_list_bottomnavigation'
                  error={formik.touched.deliverylocation && Boolean(formik.errors.deliverylocation)}
                  helperText={formik.touched.deliverylocation && formik.errors.deliverylocation}
                >
                  {/* <h4 className='delivery_location_page_content_heading'> Concourse B</h4> */}



                  {/* {deliverylocation?.map((item)=>{
                   if(item.locationType==="DROPOFF" && item.status==="ACTIVE" && item.locationName.charAt(0)==='B' )
                    {
                     return newlocation.push(item)
                      // return  <BottomNavigationAction label={item.locationName} className="Single_btn_select_delivery_location" value={item.locationName} style={{padding:'9px 9px',backgroundColor:'white' , margin:'10px !important'}} />
                    } 
                })} */}
                  {/* {(()=>{
                newlocation=  newlocation.reverse()
               })()} */}

                  {/* {console.log('newlocationnewlocation',newlocation)}  */}
                  {/* {newlocation?.map((item)=>{
                   if(item.locationType==="DROPOFF" && item.status==="ACTIVE" )
                    {
                      return  <BottomNavigationAction label={item.locationName} className="Single_btn_select_delivery_location" value={item.locationName} style={{padding:'9px 24px',backgroundColor:'white' , margin:'10px !important',border: "1px solid lightgrey",borderRadius:'30px',fontSize:'15px', fontFamily:'Poppins' }} />
                    } 
                })} */}

                  {/* <h4 className='delivery_location_page_content_heading'> Concourse D</h4> */}
                  {deliverylocation?.map((item , index) => {
                    if (item.locationType === "DROPOFF" && item.status === "ACTIVE") {
                      return <BottomNavigationAction key={index} label={item.locationName} className="Single_btn_select_delivery_location" value={item.locationName} />
                    }
                  })}
                </BottomNavigation>
                
              </div>
            </div>
            {/* {formik.values.deliverylocation === '' ? null: */}
             {storetype==='self' && <div className="BillDetails_page_BillTotal_wrapper">
              <div className='d_flex_center'>
                <h3>Cart Details</h3>
                <img src="/assets/images/edit-cart.svg" className='EditIcon_image' onClick={handlecartedit} style={{ cursor: 'pointer' }} />
                {/* <EditOutlinedIcon sx={{}}/> */}
              </div>
              <div className="DetailsPage-content-wrapper" style={{ paddingTop: "10px" }}>
                {cartData?.state?.stateone?.map((item ,index) => {
                  return (
                    <div key={index} className='d_grid_column BillDetails_item_total_wrapper'>
                      <div className="text_align_start"> <p > {item.en.name}</p></div>
                      <div className="text_align_end"> <h4 > x{item.count}</h4></div>
                    </div>
                  )
                })}
              </div>
            </div> }
           
            <div className="Select_delivery_page_next_btn_wrapper">
                  {validation ?
                  <p className="SelectDelivery_page_error_p"> Please select delivery location</p> : null}
                <button className="ItemList_Page_Next_button" type="submit"> Next
                <ArrowForwardIosIcon />
              </button>
            </div>
            {/* } */}
          </form>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default SelectDelivery
