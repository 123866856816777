import { FormControl, MenuItem, Select } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import i18next from 'i18next';
import { useEffect } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HomeIcon from '@mui/icons-material/Home';
function HelpPageHeader(props) {

  const[currentlanguage, setcurrentlanguage] =useState("Italian")
  const language = localStorage.getItem('language')
  const handlelogoutbtn=()=>{
	   localStorage.setItem('email',null)
       localStorage.setItem('password',null)
	   localStorage.setItem('language', null)
  }
  const navigate= useNavigate()
  const handleBackbutton=()=>{
	navigate(-1)
  } 
  const [isscroll, setisscroll]=useState(false)
  useEffect(()=>{
	window.addEventListener('Scroll', setisscroll(!isscroll));
  },[])

  const handlehelp=()=>{
     navigate('/help')
  }
  const handlehomeicon=()=>{
    navigate('/storelist')
  }
   return (
    <div className={props.open || props.opentwo ? 'MainHeader-wrapper_copy headerscroll':'MainHeader-wrapper'}>
		{/* {console.log('isscroll',isscroll)} */}
       <div className='MainHeader-logo-div-wrapper'>
	   <div className='MainHeader-logo-div'>
        <p> <ArrowBackIosNewIcon onClick={handleBackbutton} style={{cursor:'pointer', fontSize:'39.4px', color:"#40B4D0"}}/> </p>
		 <Link to="/" onClick={handlelogoutbtn}><img src="/assets/images/orderatcvg.png" />
		 </Link>
	   </div>  
       <div className='MainHeader_second_div'>     
          <HomeIcon onClick={handlehomeicon} sx={{color:"#40B4D0" ,fontSize:'35px' , cursor:'pointer'}}/>
      </div>
	 </div>   
    </div>
  )
}

export default HelpPageHeader
