import React from 'react'
import HomeIcon from '@mui/icons-material/Home';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Navigate, useNavigate } from 'react-router-dom';
function ProductPageHeader(props) {

    const navigate= useNavigate()
  return (
    <div className={props.open ?'Product_page_header_wrapper_two' :"Product_page_header_wrapper" }>

       <div className='Product_page_outer_div' style={{backgroundImage:`url(${props.storedata?.state?.item?.banner})` , cursor:'pointer'}} onClick={()=>{navigate('/storelist')}}>
          <div className='Product_page_outer_div_inner_wrapper'> 
             <div className='Product_page_inner_div_one'>   
              <HomeIcon sx={{color:'#40B4D0' , fontSize:'50px'}} onClick={()=>{
                navigate('/storelist')
              }}/>
                <div style={{textAlign:'left'}}>
                  <h3> {props.storedata?.state?.item?.en.name}</h3>
                  <h5>{props.storedata?.state?.item?.location}</h5>
                </div>      
             </div>
             {/* {console.log('props.storedata',props.storedata)} */}
         <p> {props.storedata?.state?.item?.en.description}</p>
         </div>
         {/* <div className='Product_page_inner_div_two'> 
          <img src='/assets/images/SideLogo.svg'/>
        </div> */}
      </div>  
      
    </div>
  )
}

export default ProductPageHeader
