import { FormControl, MenuItem, Select } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import i18next from 'i18next';
import { useEffect } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
function BillDetailsHeader(props) {
  
  const navigate= useNavigate()
  const handleBackbutton=()=>{
	navigate(-1)
  } 
  const [isscroll, setisscroll]=useState(false)
  useEffect(()=>{
	window.addEventListener('Scroll', setisscroll(!isscroll));
  },[])

  const handlehelp=()=>{
     navigate('/help')
  }
   return (
    <div className={props.open || props.opentwo ? 'MainHeader-wrapper_copy headerscroll':'MainHeader-wrapper'}>
		{/* {console.log('isscroll',isscroll)} */}
       <div className='MainHeader-logo-div-wrapper'>
	   <div className='MainHeader-logo-div'>
        <p> <ArrowBackIosNewIcon onClick={handleBackbutton} style={{cursor:'pointer', fontSize:'39.4px', color:"#40B4D0"}}/> </p>
	     <div className='BillDetails_Header_title_wrapper'>
            <h3>{props?.billdata?.en.name}</h3>
            <h6>{props?.billdata?.location} </h6>
         </div>
       </div>  
        <div className='MainHeader_second_div'>
            
		<span style={{ marginTop: '5px' , cursor:'pointer'}} onClick={handlehelp}> <HelpOutlineOutlinedIcon sx={{color:'#40B4D0'}}/> </span>  Help

      </div>
	 </div>   
    </div>
  )
}

export default BillDetailsHeader